import React from 'react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';

export default class Header extends React.Component {
  render = () => {
    return (
      <header className="header">
        <div
          style={{
            margin: '0 auto',
            maxWidth: 960,
            padding: '1.45rem 1.0875rem',
            display: 'flex',
            justifyItems: 'space-between',
            alignItems: 'center',
          }}
        >
          <h1 style={{ margin: 0, flex: 1 }}>
            <Link
              to="/"
              style={{
                color: 'white',
                textDecoration: 'none',
              }}
            >
              {/* {this.props.siteTitle} */}
            </Link>
          </h1>
          <div>
            <nav>
              <ul style={{ display: 'flex', flex: 1, paddingLeft: '0', marginBottom: '0' }}>
                {this.props.menuLinks.map(link => (
                  <li
                    className="nav-item"
                    key={link.name}
                    style={{
                      listStyleType: `none`,
                      padding: `.5rem`,
                    }}
                  >
                    <Link style={{ color: `white` }} to={link.link}>
                      {link.name}
                    </Link>
                  </li>
                ))}
              </ul>
            </nav>
          </div>
        </div>
      </header>
    );
  };
}

Header.propTypes = {
  siteTitle: PropTypes.string,
};

Header.defaultProps = {
  siteTitle: ``,
};
