/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';

import Header from './header';
import './layout.css';

import profpic from '../images/profpic3.jpg';

const Layout = ({ children, changeTheme }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
          menuLinks {
            name
            link
          }
        }
      }
    }
  `);

  return (
    <>
      <Header menuLinks={ data.site.siteMetadata.menuLinks } siteTitle={ data.site.siteMetadata.title } />
      <div
        style={ {
          margin: `0 auto`,
          maxWidth: 960,
          padding: `0 1.0875rem 1.45rem`,
        } }
      >
        <div className="hero-banner">
          <div className="hero-profpic-name">
            <img className="hero-profpic" alt="Mike" src={ profpic }></img>
            <h1 className="hero-name">Mike Gallagher</h1>
          </div>
          <div className="hero-tagline">
            <h2>Customer Success Manager with Web Development Experience</h2>
          </div>
        </div>
        <main>{ children }</main>
        <footer className="footer">© { new Date().getFullYear() }</footer>
      </div>
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
